import { Typography } from "@mui/material";
import MeetUs from "assets/illustrations/meet-us.svg";
import { PageHeader } from "components/utils/PageHeader";
import { ABOUT_US_SECTIONS } from "pages/about/sections";

export const About = () => {
	const data = ABOUT_US_SECTIONS.map((section) => (
		<div className="space-y-2" key={section.title}>
			<Typography
				variant="h3"
				fontWeight={600}
				className="text-transparent bg-clip-text bg-gradient-to-r from-[#6c63ff] to-[#867eff]"
			>
				{section.title}
			</Typography>
			<Typography variant="h6">{section.message}</Typography>
		</div>
	));

	return (
		<div className="page-padding">
			<PageHeader title="About Us" svg={MeetUs} />
			<div className="space-y-24 md:px-18 lg:px-36 xl:px-44">{data}</div>
		</div>
	);
};
