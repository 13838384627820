import ArielWimasor from "assets/images/clients/ariel-wimasor-logo.png";
import Bravo from "assets/images/clients/bravo-logo.png";
import Ecoservice from "assets/images/clients/ecoservice-logo.png";
import Elpam from "assets/images/clients/elpam-logo.png";
import Hamat from "assets/images/clients/hamat-logo.jpg";
import Harel from "assets/images/clients/harel-logo.png";
import Interpaz from "assets/images/clients/interpaz-logo.png";
import Lipski from "assets/images/clients/lipski-logo.png";
import Mazor from "assets/images/clients/mazor-logo.png";
import Menolead from "assets/images/clients/menolead-logo.png";
import MokedAmnor from "assets/images/clients/moked-amnor-logo.png";
import Ness from "assets/images/clients/ness-logo.png";
import One from "assets/images/clients/one-logo.png";
import Perrigo from "assets/images/clients/perrigo-logo.png";
import Sano from "assets/images/clients/sano-logo.png";
import Shomera from "assets/images/clients/shomera-logo.jpg";
import Weizmann from "assets/images/clients/weizmann-logo.png";

export const CLIENTS_DATA: Record<
	string,
	{
		name: string;
		longName?: string;
		imageSource: string;
		link: string;
		info: string;
	}
> = {
	weizmann: {
		name: "Weizmann Institute of Science",
		imageSource: Weizmann,
		link: "https://www.weizmann.ac.il/pages/",
		info: `
        The Weizmann Institute of Science is a public research university in Rehovot,
        Israel, established in 1934, 14 years before the State of Israel.
        It differs from other Israeli universities in that it offers only
        postgraduate degrees in the natural and exact sciences. It is a multidisciplinary research center,
        with around 3,800 scientists, postdoctoral fellows, Ph.D. and M.Sc. students, and scientific, technical,
        and administrative staff working at the institute. As of 2019, six Nobel laureates 
        and three Turing Award winners have been associated with the Weizmann Institute of Science.
        `,
	},
	sano: {
		name: "Sano",
		imageSource: Sano,
		link: "https://www.sano-international.com/",
		info: `
        Sano group is the no.1 israeli manufacturer of non -food items.
        Every third household in Israel uses sano products.
        20.5 % leading israel's competitve market.
        25% of non-food itens of any given shopping cart in Israel are sano products.
        More than 50 years of excellence.
        More than 1600 employees.
        Over 2000 products.
        Runs 5 factories.
        `,
	},
	perrigo: {
		name: "Perrigo",
		imageSource: Perrigo,
		link: "https://www.perrigo.com/",
		info: `
        Perrigo Israel develops, manufactures and markets Generic
        Pharmaceuticals and Active Pharmaceutical ingredients (API’s).
        Perrigo Israel has three manufacturing sites worldwide – two in Israel
        and one in India – and employs 1,100 highly trained staff, including
        Ph.D.s and life sciences professionals. More than two-thirds of the
        company’s sales are generated by export.
        `,
	},

	harel: {
		name: "Harel",
		longName: "Harel Insurance Investments & Financial Services LTD",
		imageSource: Harel,
		link: "https://www.harel-group.co.il/investor-relations/investor-relations/Pages/default.aspx",
		info: `
        Harel Insurance Investments and Financial Services Ltd. Group is active in the
        insurance and financial services industries - health insurance (medical, long-term
        care, dental insurance and travel insurance), non-life insurance (motor insurance,
        homeowners insurance, liabilities (indemnity) insurance, mortgage insurance and
        credit insurance), life assurance, pension funds, provident funds, education
        funds, mutual funds, portfolio management, ETFs and other financial services.   
        Harel Group has more than 80 years of insurance experience.
        `,
	},

	ness: {
		name: "Ness Technologies",
		imageSource: Ness,
		link: "https://www.ness-tech.co.il",
		info: `
        Ness is a private company that specializes in providing comprehensive computing services in
        the business sector and the educational sector. 
        The company is highly experienced and one of the leaders in the small and medium business
        market (SMB) And in the education market in Israel. Specializes in providing comprehensive
        and unique solutions and provides complete integrative solutions from the planning stage to
        successful implementation, According to the unique needs of the customer.
        A variety of computing solutions.
        `,
	},

	lipski: {
		name: "Lipski",
		imageSource: Lipski,
		link: "https://lipski.co.il",
		info: `
        Lipsky Plumbing and Sanitation Products - the leading company in the
        country in the production of plastic construction inputs.
        Established in the -60 and was the first company that developed the
        plastic threaded fittings plumbing fixtures, today it retains its position
        as the leader in manufacturing plastic construction inputs such
        as: products Ainsttzih sanitary, plumbing pipes, sanitary products
        made of plastic, a variety of equipment Lainstlatorim and rattan
        products are designed.
        Lipsky has a wide and varied product line, which allows for
        comprehensive and effective solutions.
        The company's products meet the most stringent Israeli standards.
        Hamat Group offers a perfect solution - Total Look For
        architects, professionals and the private consumer required in all
        planning and design of bathrooms and toilets.
        `,
	},

	hamat: {
		name: "Hamat",
		imageSource: Hamat,
		link: "https://en.hamat.co.il/",
		info: `
        Hemet created may be taps largest and leading, located in Eze the
        northern Ashdod, and is engaged in developing, manufacturing and
        marketing superior quality faucets modern design. Hemet is one of the
        leading exporters in Israeli industry. Invests heavily in the development
        of advanced technology, designs unique and innovative, the selection
        of quality raw materials and production processes and finishing One of
        the most advanced in the world.
        All Hamat products are manufactured in accordance with strict local and
        international standards, under strict quality control.
        The Hamat plant in Ashdod employs about 200 workers, throughout the
        entire production phase, from the initial design to the final finish, from
        the casting stage, through the turning, polishing to the finish - in a
        variety of styles.
        Hamat makes sure to provide professional and reliable service to its
        customers and invests a lot, so that the bathing and use experience will
        be perfect in the bath, shower, sink and kitchen.
        `,
	},

	shomera: {
		name: "Shomera",
		imageSource: Shomera,
		link: "https://www.shomera.co.il/Pages/HomePage.aspx",
		info: `
        The company was founded in 1943. And here is one of the oldest
        companies in the insurance industry in Israel. From its early years until
        the early 1990s, the company operated in the field of windshield
        insurance in the business sector.
        `,
	},

	one: {
		name: "One",
		imageSource: One,
		link: "https://www.one1.co.il/en/",
		info: `
        ONE is one of Israel leading IT companies offering software,
        hardware and integration services to a wealth of future
        thinking clients. ONE has accumulated years of rich
        experience and deep understanding of our clients business
        needs today, to deal with tomorrow’s challenges.
        ONE accompanies its clients in implementing the changes and
        developing the skills needed for future challenges. ONE
        employ’s nearly 4,000 experts in Israel and abroad who
        provide computer based solutions to over 2,000 clients and
        tens of thousands of users in all business sectors, from
        industry to government, whilst complying with international
        quality, service and professional standards.
        `,
	},

	bravo: {
		name: "Bravo Club",
		imageSource: Bravo,
		link: "http://www.bravo-israel.com/bravo-club/",
		info: `
        Is a platform that promotes brands and innovation, encourages creativity and
        enables learning and enrichment for managers,
        This is a unique arena that distills for marketers and advertisers, at one hour every
        month, a world of marketing innovation, creativity and inspiration, in an
        innovative format: by ten meetings once a month in Zoom.
        `,
	},
	interpaz: {
		name: "Interpaz",
		longName: "Interpaz technologics LTD",
		imageSource: Interpaz,
		link: "https://www.kerenby.co.il",
		info: `
        Interpaz technologics ltd is leading the parking revolution in Israel, the company,
        which was established in 1996, produces advanced solutions for doubling parking -
        hydraulic, concealed and robotic systems - that allow tenants to enjoy a maximum
        number of parking spaces on limited areas.
        `,
	},

	ecoservice: {
		name: "Ecoservice",
		imageSource: Ecoservice,
		link: "https://www.ecoservice.co.il",
		info: `
        Ecoservice is a family company that was founded in 2001 by Yuki Hadar and is now
        considered one of the leading water companies in the field, mainly due to our
        commitment to providing the best service.
        `,
	},

	arielWimasor: {
		name: "Ariel Wimasor",
		imageSource: ArielWimasor,
		link: "https://ariel-wimasor.ysbgroup.com/home-EN.html",
		info: `
        Ariel Wimasor is the leading company in Israel in the fields of planning, supplying, installing
        and providing maintenance for traffic control systems, tunnels control and
        command, traffic lights systems, entrance and parking control systems
        combined with LPR technologies, automatic payment systems, electronic
        signposting, parking meters, directing systems for indoor/outdoor parking lots,
        biometrics and CCTV (closed circuit television).
        The company has been active since 1971. It is a leader in its field and it
        benefits from technological superiority thanks to its experienced,
        knowledgeable and professional human capital, and its proven ability in
        performing complex projects with a comprehensive integrative vision.
        `,
	},

	menolead: {
		name: "Menolead",
		longName: "Menolead Herut Systems",
		imageSource: Menolead,
		link: "https://menolead.ysbgroup.com/home-EN.html",
		info: `
        Menolead Herut Systems of the YSB Group is one of the leading
        contracting companies in Israel. The Company specializes in planning,
        integration, establishment, management and maintenance of air conditioning,
        plumbing, electricity, infrastructure, lighting and communication systems.
        Since its foundation in 1974 and up to this day, the company has acquired
        experience and knowledge in performing hundreds of projects and in
        cooperative projects around the country.
        `,
	},

	mazor: {
		name: "Mazor Mental Health Center",
		imageSource: Mazor,
		link: "https://www.mazorhealth.co.il",
		info: `
        The Mazor Mental Health Center was founded in 1954 as a work village for
        chronic patients and the last active beds added were in 1980. The hospital serves the
        Upper, Lower and Western Galilee region, numbering about 850,000 people, it is the
        only mental health center in the area.
        The hospital has 309 hospital beds and 60 beds in a community preparation unit, in
        addition to 25 day patients. The center provides a variety of psychiatric services to
        the area, from crisis intervention to rehabilitation for those aged 18 and over.
        The hospital has large areas of landscape, trees and gardening. The hospital staff
        makes great efforts to cultivate the area for the convenience of patients and visitors.
        `,
	},

	elpam: {
		name: "Elpam Electronics",
		imageSource: Elpam,
		link: "https://www.elpam.com",
		info: `
        Elpam Electronics Company Ltd. was founded in 1972. Over the years, the company
        expanded operations into the State of Israel to the global arena, making development
        Advanced technologies in electronics, in order to meet the challenges of fulfilling diverse
        needs of National security, in technical development capability includes a wide range,
        between the various activities include planning, service regulations, consulting and
        production of specialized electronic products and sophisticated.
        `,
	},

	mokedAmnor: {
		name: "Moked Amnor",
		imageSource: MokedAmnor,
		link: "https://amnor.co.il",
		info: `
        Amnor Caesarea specializes in patrol, protection and security
        services. The company was founded in 1985 by the Moked Emun
        Group to provide a concrete solution to the severe security issues
        that were troubling Caesarea at the time. Since its founding the
        company has unceasingly provided safety and security services in
        town to the satisfaction of the residents.
        `,
	},
};
