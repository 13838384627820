import { useNavigate } from "react-router-dom";
import { UIStore } from "UIStore";

import { Grid, Typography } from "@mui/material";
import { MyButton } from "components/utils/form/MyButton";
import { LINKS_DATA } from "config/links";
import { useCallback } from "react";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";

export default function PagePreview({
	title,
	svg,
	buttonTitle = undefined,
	link = undefined,
	opposite = false,
}: {
	title: string;
	svg: string;
	buttonTitle?: string;
	link?: string;
	opposite?: boolean;
}) {
	const isMd = useIsBreakpoint("md");
	const navigate = useNavigate();

	const linkTo = useCallback(() => {
		if (link) {
			UIStore.update((s) => {
				s.transparentNavbar = link === LINKS_DATA.main.to;
			});
			navigate(link);
			window.scrollTo(0, 0);
		}
	}, [navigate, link]);

	const imagePart = (
		<Grid item xs={12} md={6} lg={5}>
			<div className="p-6">
				<img src={svg} alt="" />
			</div>
		</Grid>
	);

	return (
		<div
			className={
				"page-padding sticky top-0 h-screen flex-center w-full " +
				(opposite ? "bg-gray-100" : "bg-white")
			}
		>
			<Grid
				container
				justifyContent="space-around"
				alignItems="center"
				className="w-full"
			>
				{opposite && imagePart}
				<Grid item xs={12} md={6} lg={5}>
					<div className="p-6 items-center md:items-start flex flex-col">
						<Typography
							variant="h2"
							fontWeight={600}
							align={isMd ? "left" : "center"}
						>
							{title}
						</Typography>
						{buttonTitle && (
							<div className="mt-6">
								<MyButton
									title={buttonTitle}
									onClick={linkTo}
								/>
							</div>
						)}
					</div>
				</Grid>
				{!opposite && imagePart}
			</Grid>
		</div>
	);
}
