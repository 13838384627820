import { useEffect } from "react";

import Clients from "assets/illustrations/clients.svg";
import Contact from "assets/illustrations/contact.svg";
import Investors from "assets/illustrations/investors.svg";
import MeetUs from "assets/illustrations/meet-us.svg";
import Products from "assets/illustrations/products.svg";
import Superhero from "assets/illustrations/superhero.svg";
import { EZGLandingScreen } from "pages/main/EZGLandingScreen";
import { LandingScreen } from "pages/main/LandingScreen";
import PagePreview from "pages/main/PagePreview";
import { UIStore } from "UIStore";

export const MainPage = () => {
	useEffect(() => {
		UIStore.update((s) => {
			s.transparentNavbar = true;
		});
	}, []);

	return (
		<>
			<LandingScreen />
			<EZGLandingScreen />
			<PagePreview
				title="Hi, Investors"
				buttonTitle="Let's talk business"
				link="/investors"
				svg={Investors}
				opposite
			/>
			<PagePreview
				title="Check Our Products"
				buttonTitle="Take a tour"
				link="/products"
				svg={Products}
			/>
			<PagePreview
				title="Meet Our Clients"
				buttonTitle="Take a look"
				link="/clients"
				svg={Clients}
				opposite
			/>
			<PagePreview
				title="Meet Our Heroes"
				buttonTitle="Our team"
				link="/team"
				svg={Superhero}
			/>
			<PagePreview
				title="Know Us Better"
				buttonTitle="About us"
				link="/about"
				svg={MeetUs}
				opposite
			/>
			<PagePreview
				title="Keep In Touch"
				buttonTitle="Contact us"
				link="/contact"
				svg={Contact}
			/>
		</>
	);
};
