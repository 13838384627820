import emailjs from "@emailjs/browser";
import { Grid, Typography } from "@mui/material";
import { MyButton } from "components/utils/form/MyButton";
import { MyTextArea } from "components/utils/form/MyTextArea";
import { MyTextField } from "components/utils/form/MyTextField";
import {
	SimpleMessageModal,
	SimpleMessageModalProps,
} from "components/utils/SimpleMessageModal";
import { REGEX, SERVICE_ID, TEMPLATE_ID, USER_ID } from "config/contact";
import { useCallback, useState } from "react";
import { useModal } from "react-easy-use-modal";

const checkValidity = (fullName: string, from: string, message: string) => {
	let errorMessage = "";
	if (!REGEX.fullName.test(fullName)) {
		errorMessage += "Your full name is invalid. ";
	}
	if (!REGEX.mail.test(from.toLowerCase())) {
		errorMessage += "Your email is invalid. ";
	}
	if (message.length === 0) {
		errorMessage += "Your message can't be empty. ";
	}

	return [errorMessage === "", errorMessage];
};

export const ContactForm = () => {
	const [fullName, setFullName] = useState("");
	const [from, setFrom] = useState("");
	const [message, setMessage] = useState("");
	const [sending, setSending] = useState(false);

	const { openModal } = useModal();

	const sendMail = useCallback(async () => {
		const templateParams = {
			fullName,
			from: from,
			message: message,
		};

		const [valid, errorMessage] = checkValidity(fullName, from, message);
		if (valid) {
			setSending(true);
			try {
				await emailjs.send(
					SERVICE_ID,
					TEMPLATE_ID,
					templateParams,
					USER_ID
				);
				setSending(false);
				setFullName("");
				setFrom("");
				setMessage("");
				openModal<SimpleMessageModalProps>({
					component: SimpleMessageModal,
					props: {
						title: "Message sent 💌",
						message:
							"Your message has been sent. We will get back to you as soon as possible.",
					},
				});
			} catch (error) {
				openModal<SimpleMessageModalProps>({
					component: SimpleMessageModal,
					props: {
						title: "Error occurred 😕",
						message:
							"Something went wrong. Please try again later.",
					},
				});
			}

			setSending(false);
		} else {
			openModal<SimpleMessageModalProps>({
				component: SimpleMessageModal,
				props: {
					title: "Invalid values",
					message: String(errorMessage),
				},
			});
		}
	}, [from, fullName, message, openModal]);

	return (
		<div className="bg-gray-100 rounded-2xl p-6 lg:p-12">
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<MyTextField
						label="Full Name"
						placeholder="John Doe"
						value={fullName}
						setValue={setFullName}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<MyTextField
						label="Email"
						placeholder="johndoe@example.com"
						value={from}
						setValue={setFrom}
					/>
				</Grid>
				<Grid item xs={12}>
					<MyTextArea
						label="Message"
						placeholder="Write your message here..."
						value={message}
						setValue={setMessage}
					/>
				</Grid>
				<Grid item xs={12}>
					{!sending && (
						<MyButton
							title={"Send"}
							onClick={sendMail}
							className="w-full mt-4 p-5"
						/>
					)}
					{sending && (
						<Typography>
							Sending, please wait a second...
						</Typography>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
