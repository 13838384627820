import React from "react";

export const MyButton = ({
	title,
	onClick = undefined,
	className = "",
}: {
	title: string;
	onClick?: () => void;
	className?: string;
}) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={
				className +
				" bg-[#6c63ff] p-3 text-white text-lg font-bold rounded-lg shadow-lg hover:shadow-xl transition duration-200 focus:outline-none focus:border-0"
			}
		>
			{title}
		</button>
	);
};
