import { Grid, Typography } from "@mui/material";

import pdf1 from "assets/pdfs/BYND CANNASOFT DEC 2023 FOR SEDAR.pdf";
import pdf2 from "assets/pdfs/BYND Cannasoft MDA December 2023.pdf";
import pdf7 from "assets/pdfs/CODE OF CONDUCT.pdf";
import pdf3 from "assets/pdfs/Form of Proxy AGSM.pdf";
import pdf5 from "assets/pdfs/Notice of Annual General and Special Meeting and Information Circular.pdf";
import pdf6 from "assets/pdfs/VIG AGSM.pdf";

const purpleHover = "hover:text-[#6c63ff]";

export const InvestorsLinks = () => {
	return (
		<Grid container spacing={2}>
			<Grid item>
				<Typography>
					<a
						rel="noreferrer"
						target="_blank"
						href={
							"https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:fd4527b9-107a-38b6-9aaa-e88568b68d1b"
						}
						className={purpleHover}
					>
						Corporate Deck
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a
						rel="noreferrer"
						target="_blank"
						href={
							"https://www.sec.gov/edgar/browse/?CIK=1888151&owner=exclude"
						}
						className={purpleHover}
					>
						Financial Filings
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a
						rel="noreferrer"
						target="_blank"
						href={
							"https://www.sedar.com/DisplayProfile.do?lang=EN&issuerType=03&issuerNo=00050734"
						}
						className={purpleHover}
					>
						SEDAR
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a href={pdf1} download className={purpleHover}>
						BYND CANNASOFT DEC 2023 FOR SEDAR
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a href={pdf2} download className={purpleHover}>
						BYND Cannasoft MDA December 2023
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a href={pdf3} download className={purpleHover}>
						Form of Proxy ASGM
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a href={pdf5} download className={purpleHover}>
						Notice of Annual General and Special Meeting and
						Information Circular
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a href={pdf6} download className={purpleHover}>
						VIG AGSM
					</a>
				</Typography>
			</Grid>
			<Grid item>
				<Typography>•</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<a href={pdf7} download className={purpleHover}>
						Code of Conduct
					</a>
				</Typography>
			</Grid>
		</Grid>
	);
};
