import { Route, Routes } from "react-router-dom";

import { Footer } from "navigation/Footer";
import { NavBar } from "navigation/NavBar";
import { PageNotFound } from "pages/not-found/PageNotFound";

import { LINKS_DATA } from "../config/links";

export default function Routing() {
	const switchData = Object.values(LINKS_DATA).map((item) => {
		const ComponentPage = item.component;
		return (
			<Route path={item.to} key={item.to} element={<ComponentPage />} />
		);
	});

	return (
		<div>
			<NavBar />
			<Routes>
				{switchData}
				<Route path="*" element={<PageNotFound />} />
			</Routes>
			<Footer />
		</div>
	);
}
