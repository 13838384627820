import { Grid, Typography } from "@mui/material";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";
import React, { FC } from "react";

export const ProductView = ({
	title,
	svg,
	text,
	opposite = false,
	AdditionalContent = undefined,
}: {
	title: string;
	svg: string;
	text: string;
	opposite?: boolean;
	AdditionalContent?: FC<{}>;
}) => {
	const isXl = useIsBreakpoint("xl");

	if (isXl) {
		return (
			<div className="flex-center w-full rounded-2xl bg-gray-100 p-12">
				<Grid
					container
					justifyContent="space-around"
					alignItems="center"
					spacing={4}
				>
					<Grid container xl={4}>
						<Grid item xl={12}>
							<img src={svg} alt="" />
						</Grid>
					</Grid>
					<Grid container xl={8}>
						<Grid item xl={12}>
							<Typography
								variant="h2"
								fontWeight={600}
								gutterBottom
							>
								{title}
							</Typography>
							<Typography variant={"h5"} className="">
								{text}
							</Typography>
						</Grid>
					</Grid>
					{!!AdditionalContent && (
						<Grid item xl={12}>
							<AdditionalContent />
						</Grid>
					)}
				</Grid>
			</div>
		);
	}

	return (
		<div className="flex-center w-full rounded-2xl bg-gray-100">
			<Grid container justifyContent="space-around" alignItems="center">
				{opposite && (
					<Grid item xs={12} md={6}>
						<img src={svg} alt="" />
					</Grid>
				)}
				<Grid item xs={12} md={6}>
					<Typography
						variant="h3"
						fontWeight={600}
						align="center"
						className={opposite ? "" : "p-12"}
					>
						{title}
					</Typography>
				</Grid>
				{!opposite && (
					<Grid item xs={12} md={6}>
						<img src={svg} alt="" />
					</Grid>
				)}
				<Grid item xs={12} className="p-6 md:p-12">
					<Typography variant="h6">{text}</Typography>
				</Grid>
				{!!AdditionalContent && (
					<Grid
						item
						xs={12}
						justifyContent="center"
						alignItems="center"
						className="px-6 pb-6 md:px-12 md:pb-12"
					>
						<AdditionalContent />
					</Grid>
				)}
			</Grid>
		</div>
	);
};
