import React, { PropsWithChildren, useEffect, useState } from "react";

export const FadeIn = ({
	children,
	className = "",
}: PropsWithChildren<{ className?: string }>) => {
	const [beforeAnimation, setBeforeAnimation] = useState(true);
	useEffect(() => {
		setBeforeAnimation(false);
	}, []);

	return (
		<div
			className={
				"w-full transition transform duration-[3000ms] " +
				(beforeAnimation ? "translate-y-4 opacity-0 " : " ") +
				className
			}
		>
			{children}
		</div>
	);
};
