import Img1 from "assets/images/products/knowledge/knowledge-1.png";
import Img2 from "assets/images/products/knowledge/knowledge-2.png";
import Img3 from "assets/images/products/knowledge/knowledge-3.png";
import { CarouselWrapper } from "components/utils/CarouselWrapper";
import { CAROUSEL_ONE_ITEM } from "pages/products/additional-content/carouselResponsive";

export const KnowledgeCarousel = () => {
	return (
		<CarouselWrapper
			images={[Img1, Img2, Img3]}
			responsive={CAROUSEL_ONE_ITEM}
		/>
	);
};
