import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";
import React from "react";

export const PageHeader = ({ title, svg }: { title: string; svg: string }) => {
	const isXl = useIsBreakpoint("xl");

	return (
		<Grid container justifyContent="space-around" alignItems="center">
			<Grid item xs={12} md={6}>
				<Typography
					variant={isXl ? "h1" : "h2"}
					fontWeight={600}
					align="center"
				>
					{title}
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<img src={svg} alt="" />
			</Grid>
		</Grid>
	);
};
