import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Logo from "assets/logo/cannasoft.svg";
import { FadeIn } from "components/utils/FadeIn";
import { COMPANY_NAME } from "config/cannasoft";
import { LINKS_DATA } from "config/links";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";
import { UIStore } from "UIStore";

export const LandingScreen = () => {
	const navigate = useNavigate();
	const isXl = useIsBreakpoint("xl");
	const isMd = useIsBreakpoint("md");

	return (
		<div className="sticky top-0 h-screen flex-center bg-gradient-to-br from-indigo-900 to-[#0e122f]">
			<FadeIn>
				<Grid
					container
					spacing={3}
					justifyContent="space-evenly"
					alignItems="center"
				>
					<Grid item xs={12} lg={6}>
						<div className="flex-col-center">
							<div>
								<Typography variant="h4" color="white">
									We Are
								</Typography>
								<Typography
									variant={isXl ? "h1" : isMd ? "h2" : "h3"}
									fontWeight={600}
									color="white"
								>
									{COMPANY_NAME}
								</Typography>
								<div className="flex justify-between">
									<Typography
										variant="h6"
										fontWeight={550}
										className="text-[#22ac98] hover:text-[#e5672f]"
									>
										<button
											onClick={() => {
												UIStore.update((s) => {
													s.transparentNavbar = false;
												});
												navigate(
													LINKS_DATA.investors.to
												);
												window.scrollTo(0, 0);
											}}
										>
											Our Stock
										</button>
									</Typography>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} lg={6}>
						<div className="flex-col-center mt-12 md:mt-0">
							<img
								className="h-72 md:h-96"
								src={Logo}
								alt={COMPANY_NAME}
							/>
						</div>
					</Grid>
				</Grid>
			</FadeIn>
		</div>
	);
};
