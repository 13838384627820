import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

export const DiversityMatrix = () => {
	return (
		<TableContainer>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell>
							Country of Principal Executive Offices
						</TableCell>
						<TableCell colSpan={4}>Canada</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Foreign Private Issuer</TableCell>
						<TableCell colSpan={4}>Yes</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							Disclosure Prohibited under Home Country Law
						</TableCell>
						<TableCell colSpan={4}>No</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Total Number of Directors</TableCell>
						<TableCell colSpan={4}>9</TableCell>
					</TableRow>
					{/* <TableHead> */}
					<TableRow>
						<TableCell></TableCell>
						<TableCell style={{ fontWeight: 600 }}>
							Female
						</TableCell>
						<TableCell style={{ fontWeight: 600 }}>Male</TableCell>
						<TableCell style={{ fontWeight: 600 }}>
							Non-Binary
						</TableCell>
						<TableCell style={{ fontWeight: 600 }}>
							Did Not Disclose Gender
						</TableCell>
					</TableRow>
					{/* </TableHead> */}
					<TableRow>
						<TableCell>Directors</TableCell>
						<TableCell>2</TableCell>
						<TableCell>7</TableCell>
						<TableCell>0</TableCell>
						<TableCell>0</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							Underrepresented Individual in Home Country
							Jurisdiction
						</TableCell>
						<TableCell colSpan={4}>0</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>LGBTQ+</TableCell>
						<TableCell colSpan={4}>0</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							Did Not Disclose Demographic Background
						</TableCell>
						<TableCell colSpan={4}>0</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							Directors who are Aboriginal Peoples
						</TableCell>
						<TableCell colSpan={4}>0</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Directors with Disabilities</TableCell>
						<TableCell colSpan={4}>0</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
