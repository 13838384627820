import { FC } from "react";

import CRMProduct from "assets/illustrations/products/crm.svg";
import Knowledge from "assets/illustrations/products/knowledge.svg";
import { KnowledgeCarousel } from "pages/products/additional-content/KnowledgeCarousel";

export const PRODUCTS_DATA: {
	title: string;
	svg: string;
	text: string;
	AdditionalContent?: FC<{}>;
}[] = [
	{
		title: "Beyond CRM",
		svg: CRMProduct,
		text: `
Our CRM and ERP system is a state-of-the-art CRM designed with
years of experience. In its second generation, we wish to build
tailored software for the cannabis industry. The CRM and ERP
system will be integrated into our delivery and recommendation
system to help manage inventory, create performance reports and
financial reports. It gives the growers a deep insight to all
aspects of managing a cannabis farm and cannabis distribution
from the amounts of fertilizer right to managing delivery
revenue - all with a few clicks. Our powerful BI tools allow
growers to receive information in real-time, in great resolution
to allow optimal efficiency and profit.
		`,
	},
	{
		title: "Our Knowledge",
		svg: Knowledge,
		text: `
Everyone today knows that agriculture is an exact science. The
more information you have the better crop you are going to get,
so we wish to combine the joined knowledge of thousands of
growers throughout the world, to provide the best results. a
growing recommendation system is a machine learning-powered
platform that learns from each grower. We wish to do that by
automatically receiving information about the growing process
from all growers, using the system to allow our machine learning
algorithm to fine-tune the growing process to an exact formula
for success. We wish to give growers a recommendation program
including irrigation cycles, fertilization recommendations,
lighting cycles, and growth milestones.
		`,
		AdditionalContent: KnowledgeCarousel,
	},
];
