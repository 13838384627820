import AvnerTal from "assets/images/team/AvnerTal.jpg";
import CarmelZigdon from "assets/images/team/CarmelZigdon.jpg";
import GabiKabazo from "assets/images/team/GabiKabazo.jpg";
import GilShapira from "assets/images/team/GilShapira.jpg";
import HaroldWolkin from "assets/images/team/HaroldWolkin.jpg";
import LouisBrilleman from "assets/images/team/LouisBrilleman.jpg";
import MotiMaram from "assets/images/team/MotiMaram.jpg";
import NivShirazi from "assets/images/team/NivShirazi.jpg";
import StefaniaSzabo from "assets/images/team/StefaniaSzabo.jpg";
import YiftahBenYaackov from "assets/images/team/YiftahBenYaackov.jpg";

export type Person = {
	name: string;
	job: string;
	imageSource: string;
	info?: string;
};

export const TEAM_DATA: Person[] = [
	{
		name: "Moti Maram",
		job: "President and Director",
		info: `
        In 1986, together with a partner company, he founded a computer company that mainly provided services to
        EXATEC-compliant IBM AS 400 computers and subsequently sales and service to the first PCs in the world.
        After identifying a need for CRM and ERP software, Maram and his partner, Avner Tal,
        established a software company that manufactures CRM and ERP management software called BENEFIT in 2000.
        `,
		imageSource: MotiMaram,
	},
	{
		name: "Harold Wolkin",
		job: "Director",
		info: `
        Harold is a retired, former Investment Banker and Financial Analyst with over 40 years
        of business experience. An advisor to Chief Executive Officers and Board of Directors of
        both public and private companies. Strong financial expertise, a Chartered Financial Analyst
        (CFA) and member of the Institute of Corporate Directors.
        `,
		imageSource: HaroldWolkin,
	},
	{
		name: "Yftah Ben Yaackov",
		job: "CEO and Director",
		info: `
            Professional lawyer since 2004. Specialized in real estate related issues on all
            aspects of it including real estate corporations, complex sales transactions,
            real estate unions, real estate taxation. Notary License holder since 2014.
            Managed large projects of tens of thousands Square meters for a total amount of
            above 400 million NIS. At the last 5 years yftah has assembled the business activity
            of 2 of the largest medical cannabis company's in Israel and has an enormous
            experience and knowledge in this field.
        `,
		imageSource: YiftahBenYaackov,
	},
	{
		name: "Dr. Stefánia Szabó",
		job: "Director",
		info: `
		Stefánia is the Vice President of Pace Law Firm and Director of International Relations at
		Pace Global Advantage in Toronto, Canada. She is a former diplomat, a charismatic government services,
		management and public relations professional with 25 years of progressive experience.
		She is an international lawyer by profession, and serves as member of the executive committee 
		of the Canada-Israel Chamber of Commerce.
        `,
		imageSource: StefaniaSzabo,
	},
	{
		name: "Avner Tal",
		job: "VP Sales & Marketing, CTO and Director",
		info: `
            Technology director of Beyond Solution Company. I studied electronics and control.
            I served in the Navy and I was in the team that implemented and developed the technology
            and even carried out the experiments that included "smart" missiles. Joined Moti to
            established a computer company that supported Exatech computers and its innovative network.
        `,
		imageSource: AvnerTal,
	},
	{
		name: "Niv Shirazi",
		job: "Director",
		info: `
        Niv is a business professional with strong financial acumen. Since 2018, Mr. Shirazi serves as
		Chief Operating Officer with H.N. Win Ltd,, an Israeli real estate firm. Mr. Shirazi received
		a B.A. in Business Administration and Accounting from the Ono Academic College in Kiryat Ono, Israel.
        `,
		imageSource: NivShirazi,
	},
	{
		name: "Carmel Zigdon",
		job: "Director",
		imageSource: CarmelZigdon,
	},
	{
		name: "Gabi Kabazo",
		job: "CFO and Director",
		info: `
        Gabi is a seasoned finance and operations professional with over 20 years’ experience 
        supporting accounting, financing and IT operations in complex corporate settings. 
        Since 2009, Mr. Kabazo has been with TELUS Telecommunications Company. From 2002-2011
        he served as CFO for m-Wise Inc. He received B.A from TAU and M.B.A from UBC in Accounting & Economics.
        `,
		imageSource: GabiKabazo,
	},
	{
		name: "Louis Brilleman",
		job: "Corporate Securities Attorney",
		info: `
        Louis Brilleman has more than 20 years’ experience representing public and private companies 
		as well as investors in general corporate and securities matters. His clients span many 
		different kinds of industries. Examples include a mobile marketing technology company;
		a designer and manufacturer of electric vehicles; a marketer of dietary supplement and topical
		skin products; an underwater technology company; a designer of intelligent lighting technology;
		and a holding company for Bluefin tuna farms.
        `,
		imageSource: LouisBrilleman,
	},
	{
		name: "Gil Shapira",
		job: "Greenhouse Facility Project Manager",
		info: `
        Engineer Gil Shapira brings 30 years of prior experience in planning, managing, and implementing complex
        engineering projects. Operating both locally and internationally, Gil continues to provide customized services
        to the pharmaceutical, biotechnological, medical device, health and food industries. Thousands of square meters
        of production facilities, clean rooms, laboratories, and production equipment have been Managed designed and
        constructed By Gil Shapira beginning with basis of design to detailed design, construction, commissioning and
        finally handover.  All have been certified by the relevant authorities, such as the FDA, EMEA, GMP, GLP, FACT,
        NETCORD, JACIE, and the Israeli Ministry of Health.
        `,
		imageSource: GilShapira,
	},
];
