import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React from "react";
import { ModalProps } from "react-easy-use-modal";

export interface SimpleMessageModalProps extends ModalProps {
	title: string;
	message: string;
}

export const SimpleMessageModal = ({
	isOpen,
	onClose,
	title,
	message,
}: {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	message: string;
}) => {
	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};
