export const DISCLAIMER = `
This website contains forward-looking statements
involving risks and uncertainties that may cause
actual results to differ materially from the
statements made. Such statements reflect our current
views on future events and are subject to such risks
and uncertainties. Many factors may cause our actual
results to differ materially from those presented on
the Site, including those discussed in our filings
with Canadian or U.S. securities regulatory
authorities. If one or more of these risks and
uncertainties, such as currency fluctuations and
interest rates, increased competition, and general
economic and market factors, occur, or if the
assumptions underlying the forward-looking
statements prove to be erroneous, Actual results may
differ materially from those described herein as
intended, planned, predicted or expected. We do not
intend and assume no obligation to update these
forward-looking statements, except as required by
law. The company intends to obtain approval for the
EZ-G patent within the usual timetables andto
complete the development of the device within the
coming year. Additional regulatory standards may be
required, including FDA approval or any other
approval for manufacturing, marketing and selling
the device or capsules under therapeutic
indications. There is no certainty that the
foregoing approvals will be accepted and all
information hereunder is forward-looking. The model
of the device appearingon the websiteis not a
working device and is a simulation as a concept
model only and there is no certainty that it will be
compatible with the prototype of When the product is
manufactured in the future, there is also no
certainty that the oils supplied with the device
will belong to Foria, and the matter is subject to
the execution of the agreements between the
companies.Registration to the site is free of charge
and ordering the actual device will require monetary
consideration as presented by the company below. The
Company reserves the right to terminate the
pre-registration process at any time and does not
guarantee the timetables for the supply of the
products and/or that the product will eventually be
supplied.
`;
