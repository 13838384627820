import { Grid } from "@mui/material";
import React from "react";

import ClientsSvg from "assets/illustrations/clients.svg";
import { PageHeader } from "components/utils/PageHeader";
import { ClientCard } from "pages/clients/ClientCard";
import { CLIENTS_DATA } from "pages/clients/clientsData";

export const Clients = () => {
	return (
		<div className="page-padding">
			<PageHeader title="Our Clients" svg={ClientsSvg} />
			<Grid container spacing={4}>
				{Object.values(CLIENTS_DATA).map((client) => (
					<Grid item xs={12} sm={6} lg={4} xl={3}>
						<ClientCard
							name={client.name}
							longName={client.longName}
							imageSource={client.imageSource}
							info={client.info}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
