import { About } from "pages/about/About";
import { Clients } from "pages/clients/Clients";
import { Contact } from "pages/contact/Contact";
import { DisclaimerPage } from "pages/disclaimer/DisclaimerPage";
import { EZG } from "pages/ezg/EZG";
import { Investors } from "pages/investors/Investors";
import { Products } from "pages/products/Products";
import { Team } from "pages/team/TeamPage";

import { MainPage } from "../pages/main/MainPage";

export const LINKS_DATA = {
	disclaimer: {
		to: "/disclaimer",
		title: "Disclaimer",
		component: () => <DisclaimerPage />,
	},

	about: {
		to: "/about",
		title: "About",
		component: () => <About />,
	},

	ezg: {
		to: "/ezg",
		title: "EZG",
		component: () => <EZG />,
	},

	team: {
		to: "/team",
		title: "Team",
		component: () => <Team />,
	},

	products: {
		to: "/products",
		title: "Products",
		component: () => <Products />,
	},

	contact: {
		to: "/contact",
		title: "Contact",
		component: () => <Contact />,
	},

	clients: {
		to: "/clients",
		title: "Clients",
		component: () => <Clients />,
	},

	investors: {
		to: "/investors",
		title: "Investors",
		component: () => <Investors />,
	},

	// Keep last!
	main: {
		to: "/",
		title: "Main",
		component: () => <MainPage />,
	},
};

export const NAV_BAR_DATA = [
	LINKS_DATA.investors,
	LINKS_DATA.ezg,
	LINKS_DATA.products,
	LINKS_DATA.clients,
	LINKS_DATA.team,
	LINKS_DATA.about,
	LINKS_DATA.contact,
	LINKS_DATA.disclaimer,
];
