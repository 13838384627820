import { COMPANY_NAME } from "config/cannasoft";
import React from "react";

export const Footer = () => {
	return (
		<footer className="relative bg-gray-200 py-16">
			<div className="flex flex-wrap items-center md:justify-between justify-center">
				<div className="w-full md:w-4/12 px-4 mx-auto text-center">
					<div className="text-sm text-gray-600 font-semibold py-1">
						Copyright © {new Date().getFullYear()}
						{" - "}
						{COMPANY_NAME}
					</div>
				</div>
			</div>
		</footer>
	);
};
