import Carousel, { ResponsiveType } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const CarouselWrapper = ({
	images,
	responsive,
}: {
	images: string[];
	responsive: ResponsiveType;
}) => {
	return (
		<Carousel
			swipeable
			draggable
			showDots
			responsive={responsive}
			infinite
			autoPlay
			autoPlaySpeed={4000}
			keyBoardControl
			transitionDuration={500}
			containerClass="carousel-container"
			removeArrowOnDeviceType={["tablet", "mobile"]}
			dotListClass=""
			itemClass="p-2 "
		>
			{images.map((image, index) => (
				<img
					src={image}
					key={index}
					className="pointer-events-none"
					alt=""
				/>
			))}
		</Carousel>
	);
};
