import { Grid } from "@mui/material";
import React from "react";

import Superhero from "assets/illustrations/superhero.svg";
import { PageHeader } from "components/utils/PageHeader";
import { PersonCard } from "pages/team/PersonCard";
import { TEAM_DATA } from "pages/team/teamData";

export const Team = () => {
	return (
		<div className="page-padding">
			<PageHeader title="Our Team" svg={Superhero} />
			<Grid container spacing={4}>
				{TEAM_DATA.map((person) => (
					<Grid item xs={12} sm={6} lg={4} xl={3}>
						<PersonCard
							name={person.name}
							job={person.job}
							imageSource={person.imageSource}
							info={person.info}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
