import ProductsSvg from "assets/illustrations/products.svg";
import { PageHeader } from "components/utils/PageHeader";
import { PRODUCTS_DATA } from "pages/products/productsData";
import { ProductView } from "pages/products/ProductView";

export const Products = () => {
	return (
		<div className="page-padding">
			<PageHeader title="Our Products" svg={ProductsSvg} />
			<div className="space-y-12">
				{PRODUCTS_DATA.map((product, index) => (
					<ProductView
						title={product.title}
						svg={product.svg}
						text={product.text}
						opposite={index % 2 === 0}
						AdditionalContent={product.AdditionalContent}
					/>
				))}
			</div>
		</div>
	);
};
