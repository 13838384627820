import { Grid, Typography } from "@mui/material";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import { NASDAQ_CANNASOFT_STOCK_SYMBOL, NASDAQ_LINK } from "config/cannasoft";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";

export const Stock = () => {
	const isMd = useIsBreakpoint("md");
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={12}>
				<div className="full rounded-2xl flex-col-venter bg-gray-100 overflow-hidden">
					<div className="p-4">
						<Typography
							align={isMd ? "left" : "center"}
							variant="h4"
							fontWeight={600}
							className="text-transparent bg-clip-text bg-gradient-to-r from-[#6c63ff] via-[#867eff] to-[#854794]"
						>
							<a
								target="_blank"
								rel="noreferrer"
								href={NASDAQ_LINK}
							>
								{NASDAQ_CANNASOFT_STOCK_SYMBOL}
							</a>
						</Typography>
					</div>
					<AdvancedRealTimeChart
						hide_side_toolbar
						height={500}
						theme="dark"
						width="100%"
						symbol={NASDAQ_CANNASOFT_STOCK_SYMBOL}
					/>
				</div>
			</Grid>
		</Grid>
	);
};
