import Routing from "./navigation/Routing";
import { ModalProvider } from "react-easy-use-modal";

export const AppLoader = () => {
	return (
		<ModalProvider>
			<Routing />
		</ModalProvider>
	);
};
