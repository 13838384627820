import { Divider, Typography } from "@mui/material";

import { DISCLAIMER } from "pages/disclaimer/disclaimer";
import { DiversityMatrix } from "pages/investors/DiversityMatrix";
import { InvestorsLinks } from "pages/investors/InvestorsLinks";

export const InvestorsInformation = () => {
	return (
		<>
			<Typography variant="h6" className="text-[#6c63ff]" gutterBottom>
				Links
			</Typography>
			<InvestorsLinks />
			<div className="my-2">
				<Divider />
			</div>
			<Typography variant="h6" className="text-[#6c63ff]" gutterBottom>
				Diversity
			</Typography>
			<DiversityMatrix />
			<div className="mt-3">
				<Typography color="textSecondary">{DISCLAIMER}</Typography>
			</div>
		</>
	);
};
