import { Typography } from "@mui/material";
import React from "react";
import { useModal } from "react-easy-use-modal";

import {
	SimpleMessageModal,
	SimpleMessageModalProps,
} from "components/utils/SimpleMessageModal";

export const ClientCard = ({
	imageSource,
	name,
	info,
	longName = undefined,
}: {
	imageSource: string;
	name: string;
	info: string;
	longName?: string;
}) => {
	const { openModal } = useModal();
	return (
		<div
			onClick={() => {
				openModal<SimpleMessageModalProps>({
					component: SimpleMessageModal,
					props: {
						title: longName ?? name,
						message: info,
					},
				});
			}}
			className="full rounded-2xl p-12 flex flex-col justify-center items-center bg-gray-100 cursor-pointer"
		>
			<div className="mb-8 ">
				<img className="max-h-36" src={imageSource} alt="" />
			</div>
			<div className="flex-col-center">
				<Typography variant="h6" fontWeight={600} align="center">
					{name}
				</Typography>
			</div>
		</div>
	);
};
