import { Divider } from "@mui/material";

import Investor from "assets/illustrations/investors.svg";
import { PageHeader } from "components/utils/PageHeader";
import { InvestorsInformation } from "pages/investors/InvestorsInformation";
import { Stock } from "pages/investors/Stock";

export const Investors = () => {
	return (
		<div className="page-padding">
			<PageHeader title="Hi, Investors" svg={Investor} />
			<Stock />
			<div className="my-2">
				<Divider />
			</div>
			<InvestorsInformation />
		</div>
	);
};
