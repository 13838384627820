export const MyTextArea = ({
	label,
	placeholder,
	value,
	setValue,
	containerClassName = "",
	inputClassName = "",
}: {
	label: string;
	placeholder: string;
	value: string;
	setValue: (value: string) => void;
	containerClassName?: string;
	inputClassName?: string;
}) => {
	return (
		<label className={"block " + containerClassName}>
			<span className="text-gray-700">{label}</span>
			<textarea
				style={{ width: "100%" }}
				className={
					inputClassName +
					" form-input p-4 mt-2 block w-64 rounded-lg shadow-md focus:shadow-lg focus:ring-2 focus:ring-ks-purple font-bold transition duration-200"
				}
				placeholder={placeholder}
				onChange={(event) => {
					setValue(event.target.value);
				}}
				rows={12}
				value={value}
			/>
		</label>
	);
};
