import { Typography } from "@mui/material";
import React from "react";
import { useModal } from "react-easy-use-modal";

import {
	SimpleMessageModal,
	SimpleMessageModalProps,
} from "components/utils/SimpleMessageModal";
import { Person } from "pages/team/teamData";

export const PersonCard = ({ imageSource, name, job, info }: Person) => {
	const { openModal } = useModal();
	return (
		<div
			onClick={() => {
				if (info) {
					openModal<SimpleMessageModalProps>({
						component: SimpleMessageModal,
						props: {
							title: name,
							message: info,
						},
					});
				}
			}}
			className="w-full rounded-2xl p-12 flex flex-col justify-center items-center transition transform hover:bg-gray-50 cursor-pointer"
		>
			<div className="mb-8">
				<img
					className="object-center object-cover rounded-full h-36 w-36"
					src={imageSource}
					alt=""
				/>
			</div>
			<div className="flex-col-center">
				<Typography variant="h6" fontWeight={600} align="center">
					{name}
				</Typography>
				<Typography color="textSecondary" align="center">
					{job}
				</Typography>
			</div>
		</div>
	);
};
