import { Typography } from "@mui/material";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";
import { useNavigate } from "react-router-dom";
import "./shake.css";
import { MyButton } from "components/utils/form/MyButton";
import { useCallback } from "react";
import { UIStore } from "UIStore";
import { LINKS_DATA } from "config/links";
import EZGRight from "assets/images/products/ezg/ezg-waves-right.svg";

export const EZGLandingScreen = () => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");
	const navigate = useNavigate();
	const goToEZG = useCallback(() => {
		UIStore.update((s) => {
			s.transparentNavbar = false;
		});
		navigate(LINKS_DATA.ezg.to);
		window.scrollTo(0, 0);
	}, [navigate]);

	return (
		<div
			className={
				"overflow-hidden bg-white sticky top-0 screen relative p-12 " +
				(isMd ? "flex-vcenter justify-around " : "flex-col-center ")
			}
		>
			<div className="w-full flex-col-center">
				<Typography
					variant={isMd ? "h1" : isSm ? "h2" : "h3"}
					fontWeight={700}
					fontFamily={"Lobster Two"}
					className="text-[#b72f4a]"
					gutterBottom
				>
					EZG
				</Typography>
				<Typography
					color="textSecondary"
					variant={isMd ? "h5" : "h6"}
					textAlign="center"
					gutterBottom
				>
					CBD Oil Infused Pleasure
					<br />
					AI-Controlled
				</Typography>
				<MyButton
					title="Take a Tour"
					onClick={goToEZG}
					className="mt-3 px-5 py-3 bg-[#ebc6ce] text-[#b72f4a]"
				/>
			</div>

			<img
				src={require("assets/images/products/ezg/vib-0.png")}
				className="shake"
				style={{
					width: isMd ? 400 : isSm ? 300 : 200,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
			<img
				src={require(`assets/images/products/ezg/ai-bg-${
					isMd ? "right" : "bottom"
				}.png`)}
				className={
					"absolute shake-slower " +
					(isMd
						? "w-96 h-full right-0 top-0 "
						: "w-full left-0 bottom-0 ") +
					(isSm ? "" : "h-80")
				}
				style={{
					objectFit: "cover",
					zIndex: -10,
					opacity: 0.6,
				}}
				alt=""
			/>
			{isMd && (
				<img
					src={EZGRight}
					alt=""
					className="h-screen absolute left-0"
				/>
			)}
		</div>
	);
};
