import { PageHeader } from "components/utils/PageHeader";
import React from "react";
import NotFound from "assets/illustrations/page-not-found.svg";
import { MyButton } from "components/utils/form/MyButton";
import { useNavigate } from "react-router-dom";
import { LINKS_DATA } from "config/links";

export const PageNotFound = () => {
	const navigate = useNavigate();

	return (
		<div className="page-padding flex-col-center">
			<PageHeader title="404" svg={NotFound} />
			<MyButton
				title="Let's go home"
				onClick={() => navigate(LINKS_DATA.main.to)}
			/>
		</div>
	);
};
