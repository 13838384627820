import { Typography } from "@mui/material";
import { DISCLAIMER } from "pages/disclaimer/disclaimer";
import React from "react";

export const DisclaimerPage = () => {
	return (
		<div className="page-padding">
			<Typography variant="h5" gutterBottom>
				Disclaimer
			</Typography>
			<Typography>{DISCLAIMER}</Typography>
		</div>
	);
};
