export const CAROUSEL_RESPONSIVE_VALUES = {
	xl: {
		breakpoint: { max: 5000, min: 1200 },
		items: 4,
	},
	lg: {
		breakpoint: { max: 1200, min: 900 },
		items: 3,
	},
	md: {
		breakpoint: { max: 900, min: 600 },
		items: 2,
	},
	sm: {
		breakpoint: { max: 600, min: 0 },
		items: 1,
	},
};

export const CAROUSEL_ONE_ITEM = {
	sm: {
		breakpoint: { max: 5000, min: 0 },
		items: 1,
	},
};
