import React from "react";

import ContactSvg from "assets/illustrations/contact.svg";
import { PageHeader } from "components/utils/PageHeader";
import { ContactForm } from "pages/contact/ContactForm";

export const Contact = () => {
	return (
		<div className="page-padding">
			<PageHeader title="Contact Us" svg={ContactSvg} />
			<ContactForm />
		</div>
	);
};
