import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import ReactPlayer from "react-player";

import CannabisWallpaper from "assets/images/products/ezg/cannabis-wallpaper.jpg";
import EZGTop from "assets/images/products/ezg/ezg-waves-top.svg";
import EZGRepresentation from "assets/pdfs/ezg/Cannabis & Female Reproductive Organs.pdf";
import { useIsBreakpoint } from "hooks/useIsBreakpoint";
import { UIStore } from "UIStore";

export const EZG = () => {
	useEffect(() => {
		UIStore.update((s) => {
			s.transparentNavbar = true;
		});
	}, []);

	const isMd = useIsBreakpoint("md");

	return (
		<div>
			<img src={EZGTop} alt="" className="w-full object-cover" />
			<div className="page-padding">
				<Typography
					fontFamily={"Lobster Two"}
					variant="h1"
					fontWeight={900}
					align="center"
					className="text-[#b72f4a]"
					gutterBottom
				>
					EZG
				</Typography>
				<Typography variant="h5" align="center">
					Introducing the latest innovation in the field of women
					wellness
				</Typography>
				<div className="mt-10">
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<div className="full bg-[#b72f4a] shadow-2xl rounded-2xl overflow-hidden">
								<ReactPlayer
									controls
									className="aspect-video rounded-2xl"
									url="https://youtu.be/i1Y3wdzryW8"
									width="100%"
									height="100%"
								/>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<a href={EZGRepresentation} download>
								<div
									className="flex-col-center rounded-2xl shadow-2xl full p-12"
									style={{
										backgroundImage: `url(${CannabisWallpaper})`,
									}}
								>
									<Typography
										variant={isMd ? "h2" : "h4"}
										fontWeight={600}
										color="white"
										align="center"
										gutterBottom
									>
										Cannabis {"&"} Female Reproductive
										Organs
									</Typography>
									<Typography
										variant="h5"
										color="white"
										align="center"
									>
										Download the representation
									</Typography>
								</div>
							</a>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
};
